import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import GoogleMapReact from "google-map-react";
import { RichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";

import {
  SEO,
  PageHeaderV2,
  Icon,
  FlexCol,
  FlexRow,
  H6,
  Theme,
  RibbonCTA,
  SectionWrapper,
  Text,
} from "src/components";

import { mdiParking, mdiMapMarker } from "@mdi/js";

const YouAreHere = () => (
  <div
    css={`
      position: relative;
      height: 16px;
      width: 16px;
      border-radius: 100%;
      background-color: blue;
      box-shadow: 0px 0px 32px 2px blue;
    `}
  />
);

function FestivalMap({ data, setInvert, setInvertLogo }) {
  const [locationCategories, setLocationCategories] = useState([]);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(16);

  let festival = JSON.parse(data.festival.dataString);

  function showLocation(position) {
    setLocation({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  }

  function errorHandler(err) {
    if (err.code == 1) {
      alert("Error: Access is denied!");
    } else if (err.code == 2) {
      alert("Error: Position is unavailable!");
    }
  }
  function getLocation() {
    if (navigator.geolocation) {
      var options = { timeout: 60000 };
      navigator.geolocation.getCurrentPosition(
        showLocation,
        errorHandler,
        options
      );
    } else {
      alert("Sorry, browser does not support geolocation!");
    }
  }

  useEffect(() => {
    if (festival.use_google_map) {
      getLocation();
      let updateLocation = setTimeout(() => {
        getLocation();
      }, 20000);
      return function cleanup() {
        clearTimeout(updateLocation);
      };
    }
  }, []);

  useEffect(() => {
    if (festival.map_locations) {
      let finalCategories = [];
      festival.map_locations.forEach((location) => {
        let found = -1;
        finalCategories.forEach((category, index) => {
          if (
            category.title.toLowerCase() === location.category.toLowerCase()
          ) {
            found = index;
          }
        });
        if (found === -1) {
          finalCategories.push({ title: location.category, items: [location] });
        } else {
          finalCategories[found].items.push(location);
        }
      });
      setLocationCategories(finalCategories);
    }
  }, [festival]);

  return (
    <ThemeProvider
      theme={Theme({
        primary: festival.festival_color,
        secondary: festival.festival_secondary_color,
        tertiary: festival.festival_tertiary_color,
      })}
    >
      <>
        <SEO
          title={(festival.title && RichText.asText(festival.title)) + ` Map`}
        />
        <ThemeProvider
          theme={Theme({
            bg:
              festival.theme === "Dark theme"
                ? {
                    default: "#1B1A19",
                    alt: lighten(0.05, "#1B1A19"),
                    wash: lighten(0.1, "#1B1A19"),
                    reverse: "#FFF",
                  }
                : undefined,
            primary: festival.festival_color,
            secondary: festival.festival_secondary_color,
            tertiary: festival.festival_tertiary_color,
          })}
        >
          <PageHeaderV2
            heading={"Map"}
            links={
              festival.map_locations &&
              festival.map_locations[0] &&
              festival.map_locations[0].location1 && ["Key Locations"]
            }
            color="brand.primary"
            invert={true}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
        </ThemeProvider>
        <ThemeProvider
          theme={Theme({
            text:
              festival.theme === "Dark theme"
                ? {
                    default: "#fff",
                    body: rgba("#fff", 0.75),
                    alt: rgba("#fff", 0.5),
                    reverse: "#1B1A19",
                    reverseBody: rgba("#1B1A19", 0.75),
                    reverseAlt: rgba("#1B1A19", 0.5),
                  }
                : undefined,
            bg:
              festival.theme === "Dark theme"
                ? {
                    default: "#1B1A19",
                    alt: lighten(0.05, "#1B1A19"),
                    wash: lighten(0.1, "#1B1A19"),
                    reverse: "#FFF",
                  }
                : undefined,
            primary: festival.festival_color,
            secondary: festival.festival_secondary_color,
            tertiary: festival.festival_tertiary_color,
          })}
        >
          <>
            {!festival.use_google_map && (
              <div
                css={`
                  position: relative;
                  z-index: 0;
                  background-color: ${(props) =>
                    props.theme.colors.brand.primary};
                  &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    height: 50%;
                    background-color: ${(props) =>
                      props.theme.colors.bg.default};
                  }
                `}
              >
                <SectionWrapper>
                  {festival.map_inner_image && (
                    <img
                      src={festival.map_inner_image.url}
                      width="100%"
                      height="auto"
                    />
                  )}
                </SectionWrapper>
              </div>
            )}
            {festival.use_google_map && (
              <div
                css={`
                  min-height: 300px;
                  height: 80vh;
                  width: 100%;
                `}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_KEY }}
                  defaultCenter={{
                    lat: 51.0552308,
                    lng: -114.0710505,
                  }}
                  zoom={zoom}
                  defaultZoom={16}
                  yesIWantToUseGoogleMapApiInternals
                  onChange={(event) => {
                    setZoom(event.zoom);
                  }}
                  options={{ styles: [...mapStyles] }}
                >
                  <YouAreHere lat={location.lat} lng={location.lng} />
                  {festival.map_inner_image && (
                    <img
                      src={festival.map_inner_image.url}
                      lat={51.0552308}
                      lng={-114.0710505}
                      height={0.0078 * Math.pow(2, zoom)}
                      width="auto"
                      style={{ transform: `translate(-50%, -50%)` }}
                    />
                  )}
                </GoogleMapReact>
              </div>
            )}
            {locationCategories && locationCategories[0] && (
              <SectionWrapper id="key-locations" bg="bg.default" py={[5, 6, 7]}>
                <FlexRow flexDirection={["column", "column", "row"]}>
                  {locationCategories &&
                    locationCategories.map(({ title, items }) => (
                      <FlexCol
                        width={["100%", "100%", "50%"]}
                        mx={[0, 0, 4]}
                        mb={[4, 5, 0]}
                      >
                        {title && (
                          <FlexRow alignItems="center" mb={[2, 2, 4]}>
                            <div>
                              <Icon
                                path={
                                  title === "Parking"
                                    ? mdiParking
                                    : mdiMapMarker
                                }
                                size={1.5}
                                css={`
                                  width: 1.5rem !important;
                                  height: 1.5rem !important;
                                  fill: ${(props) =>
                                    props.theme.colors.text.default};
                                `}
                              />
                            </div>
                            <H6 children={title} ml={2} />
                          </FlexRow>
                        )}
                        {items.map((item, index) => (
                          <FlexRow
                            alignItems="flex-start"
                            width={[1, 1 / 2, null, 1]}
                            key={title + `item` + index}
                          >
                            <Text
                              as="a"
                              href={
                                item.map_link &&
                                item.map_link.url &&
                                item.map_link.url
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              children={index + 1 + ". " + item.location1}
                              fontSize={[1, 2]}
                              mb={1}
                            />
                          </FlexRow>
                        ))}
                      </FlexCol>
                    ))}
                </FlexRow>
              </SectionWrapper>
            )}
          </>
        </ThemeProvider>
        <RibbonCTA
          heading={"Learn more about the festival"}
          buttonText={"At The Festival"}
          buttonLink={{
            url: "/at-the-festival/",
          }}
          color="brand.tertiary"
          invert={true}
        />
      </>
    </ThemeProvider>
  );
}

export default FestivalMap;

export const query = graphql`
  query ($id: String!) {
    festival: prismicFestival(id: { eq: $id }) {
      dataString
    }
  }
`;

const mapStyles = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#e9e9e9",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#dedede",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#333333",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];
